.App {
  text-align: center;
}

.App-logo {
  width: 14vmin;
  pointer-events: none;
  margin: 20px auto 10px auto;
}

h5 {
  font-family: trajan-pro-3, serif;
  margin: 0;
  font-size: 26px;
}

p {
  font-family: trajan-pro-3, serif;
  font-size: 16px;
  font-weight: 400;
  margin: 10px auto 20px auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;
  padding-bottom: 75px;
}

.App-link {
  color: #61dafb;
}

.text-link, .text-link:active, .text-link:hover, .text-link:focus, .text-link:link {
  font-size: 14px;
  font-weight: 900;
  margin: 40px auto;
  color: white;
  text-decoration: none;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
